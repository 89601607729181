<template>
    <div>
        <div class="container unselectable">
            <div class="row pt-3 pb-3">
                <div class="col-12">
                    <div>
                        <input class="form-control form-control-md" type="text" placeholder="Search prescriptions using IC number...">
                    </div>
                </div>
            </div>
            <div class="row pt-3 pb-3">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <h5>Old Consultation</h5>
                            <p>Mr Ahmad wants to do consultation with you for E-prescriptions</p>
                            <div>
                                <button class="btn btn-success">View</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from './../../utils/API'

export default {
    async mounted(){

    }
}
</script>

<style scoped>
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>